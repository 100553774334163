/*! ------------------------------------------------------------------
 # Template Name: Abildon - Agency & Portfolio Template
 # Author: FLexPlate (https://ui8.net/flexplate/products)
 # Description: Agency & Portfolio Template
 # Version: 1.0.0
-------------------------*/
@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap);

body {
    font-family: "Space Grotesk", sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    color: #909090;
    background-color: #001213;
    line-height: 1.6
}

.paragraph, p {
    margin: 0;
    line-height: 1.7
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    margin: 0;
    line-height: 1.4
}

h4, h5, h6 {
    font-weight: 700
}

.h1, h1 {
    font-size: 48px
}

@media (max-width:767px) {
    .h1, h1 {
        font-size: 40px
    }
}

.h2, h2 {
    font-size: 38px
}

@media (max-width:767px) {
    .h2, h2 {
        font-size: 30px
    }
}

.h3, h3 {
    font-size: 30px
}

@media (max-width:767px) {
    .h3, h3 {
        font-size: 26px
    }
}

.h4, h4 {
    font-size: 24px
}

.h5, h5 {
    font-size: 18px
}

.h6, h6 {
    font-size: 15px
}

.line-link {
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: inline-flex;
    font-weight: 500
}

.line-link .arrow-up-right, .line-link .icon {
    position: relative;
    top: 3px;
    margin-left: 3px
}

.line-link.text-white:hover {
    color: #00ea79 !important
}

.content .line-link.h1:not(.text-white)::after, .content .line-link.h1:not(.text-white)::before, .content .line-link.h2:not(.text-white)::after, .content .line-link.h2:not(.text-white)::before, .content .line-link.h3:not(.text-white)::after, .content .line-link.h3:not(.text-white)::before, .content .line-link.h4:not(.text-white)::after, .content .line-link.h4:not(.text-white)::before, .content .line-link.h5:not(.text-white)::after, .content .line-link.h5:not(.text-white)::before, .content .line-link.h6:not(.text-white)::after, .content .line-link.h6:not(.text-white)::before, .content h1.line-link:not(.text-white)::after, .content h1.line-link:not(.text-white)::before, .content h2.line-link:not(.text-white)::after, .content h2.line-link:not(.text-white)::before, .content h3.line-link:not(.text-white)::after, .content h3.line-link:not(.text-white)::before, .content h4.line-link:not(.text-white)::after, .content h4.line-link:not(.text-white)::before, .content h5.line-link:not(.text-white)::after, .content h5.line-link:not(.text-white)::before, .content h6.line-link:not(.text-white)::after, .content h6.line-link:not(.text-white)::before, .line-link.text-dark::after, .line-link.text-dark::before {
    background-color: #001213
}

.content .line-link.h1:not(.text-white)::before, .content .line-link.h2:not(.text-white)::before, .content .line-link.h3:not(.text-white)::before, .content .line-link.h4:not(.text-white)::before, .content .line-link.h5:not(.text-white)::before, .content .line-link.h6:not(.text-white)::before, .content h1.line-link:not(.text-white)::before, .content h2.line-link:not(.text-white)::before, .content h3.line-link:not(.text-white)::before, .content h4.line-link:not(.text-white)::before, .content h5.line-link:not(.text-white)::before, .content h6.line-link:not(.text-white)::before, .line-link.text-dark::before {
    background-color: rgba(0, 183, 95, .7)
}

.content .line-link.h1:hover:not(.text-white), .content .line-link.h2:hover:not(.text-white), .content .line-link.h3:hover:not(.text-white), .content .line-link.h4:hover:not(.text-white), .content .line-link.h5:hover:not(.text-white), .content .line-link.h6:hover:not(.text-white), .content h1.line-link:hover:not(.text-white), .content h2.line-link:hover:not(.text-white), .content h3.line-link:hover:not(.text-white), .content h4.line-link:hover:not(.text-white), .content h5.line-link:hover:not(.text-white), .content h6.line-link:hover:not(.text-white), .line-link.text-dark:hover {
    color: #000 !important
}

.line-link::after, .line-link::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 0%;
    background-color: rgba(0, 183, 95, .8);
    right: 0;
    bottom: 0;
    z-index: -1;
    transition: .4s cubic-bezier(.2, 1, .3, 1);
    pointer-events: none
}

.line-link::before {
    width: 100%;
    z-index: -2;
    transition: 0s;
    background-color: rgba(0, 183, 95, .3)
}

.line-link:hover::before {
    width: 0%;
    transition: .4s cubic-bezier(.2, 1, .3, 1)
}

.line-link:hover::after {
    width: 100%;
    right: auto;
    left: 0;
    transition-delay: .3s
}

.btn {
    font-size: 15px;
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    font-weight: 500;
    border: 0;
    outline: 0;
    border-radius: 0;
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: inline-block;
    line-height: 1
}

@media (max-width:575px) {
    .btn {
        padding: 0 25px
    }
}

.btn:active, .btn:focus {
    box-shadow: none !important
}

.btn:active, .btn:focus, .btn:hover {
    outline: 0;
    box-shadow: none !important
}

.btn span {
    display: inline-block;
    height: 50px;
    line-height: 50px;
    transition: transform .65s cubic-bezier(.2, 1, .3, 1), opacity .2s
}

.btn::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    content: attr(data-text);
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    height: 100%;
    line-height: 50px;
    transition: transform .65s cubic-bezier(.2, 1, .3, 1), opacity .2s;
    transform: scale(.95) translateY(12px)
}

.btn:hover span {
    opacity: 0;
    transform: scale(.95) translateY(-12px)
}

.btn:hover::after {
    top: 0;
    opacity: 1;
    transform: scale(1) translateY(0)
}

.btn-white {
    background: #fff;
    color: #000;
    font-weight: 600
}

.btn-white:active, .btn-white:focus, .btn-white:hover {
    color: #000;
    background: #fff;
    border-color: #fff
}

.btn-black {
    background: #000;
    color: #fff
}

.btn-black:active, .btn-black:focus, .btn-black:hover {
    color: #fff;
    background: #000;
    border-color: #000
}

.btn-circle-black, .btn-circle-white {
    height: 90px;
    width: 90px;
    border: 2px solid;
    border-radius: 100%;
    font-size: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    padding-left: 2px;
    padding-bottom: 2px
}

@media (max-width:991px) {
    .btn-circle-black, .btn-circle-white {
        height: 70px;
        width: 70px
    }
}

.btn-circle-white {
    color: #000;
    border-color: #000
}

.btn-circle-white:hover {
    background-color: #000;
    color: #fff
}

.btn-circle-black {
    color: #fff;
    border-color: #fff
}

.btn-circle-black:hover {
    background-color: #fff;
    color: #000
}

::-moz-selection {
    background: #008445;
    color: #fff
}

::selection {
    background: #008445;
    color: #fff
}

ul {
    list-style-type: none;
    margin: 0
}

img {
    vertical-align: middle;
    border: 0
}

a, a:focus, a:hover {
    text-decoration: none
}

a {
    transition: .3s ease;
    color: #00b75f
}

a:hover {
    color: #00ea79
}

.content a.h1:hover:not(.text-white), .content a.h2:hover:not(.text-white), .content a.h3:hover:not(.text-white), .content a.h4:hover:not(.text-white), .content a.h5:hover:not(.text-white), .content a.h6:hover:not(.text-white), a.text-dark:hover, a.text-light:hover, a.text-white:hover {
    color: #00b75f !important
}

a, button {
    cursor: pointer
}

.outline-0, .outline-0:focus {
    outline: 0
}

.d-unset {
    display: unset !important
}

@media (min-width:1400px) {
    .container {
        max-width: 1300px
    }
}

.row.gap-lg {
    margin-left: 20px;
    margin-right: 20px
}

@media (max-width:991px) {
    .row.gap-lg {
        margin-left: -15px !important;
        margin-right: -15px !important
    }
}

.row.gap-lg .col, .row.gap-lg .col-1, .row.gap-lg .col-10, .row.gap-lg .col-11, .row.gap-lg .col-12, .row.gap-lg .col-2, .row.gap-lg .col-3, .row.gap-lg .col-4, .row.gap-lg .col-5, .row.gap-lg .col-6, .row.gap-lg .col-7, .row.gap-lg .col-8, .row.gap-lg .col-9, .row.gap-lg .col-auto, .row.gap-lg .col-lg, .row.gap-lg .col-lg-1, .row.gap-lg .col-lg-10, .row.gap-lg .col-lg-11, .row.gap-lg .col-lg-12, .row.gap-lg .col-lg-2, .row.gap-lg .col-lg-3, .row.gap-lg .col-lg-4, .row.gap-lg .col-lg-5, .row.gap-lg .col-lg-6, .row.gap-lg .col-lg-7, .row.gap-lg .col-lg-8, .row.gap-lg .col-lg-9, .row.gap-lg .col-lg-auto, .row.gap-lg .col-md, .row.gap-lg .col-md-1, .row.gap-lg .col-md-10, .row.gap-lg .col-md-11, .row.gap-lg .col-md-12, .row.gap-lg .col-md-2, .row.gap-lg .col-md-3, .row.gap-lg .col-md-4, .row.gap-lg .col-md-5, .row.gap-lg .col-md-6, .row.gap-lg .col-md-7, .row.gap-lg .col-md-8, .row.gap-lg .col-md-9, .row.gap-lg .col-md-auto, .row.gap-lg .col-sm, .row.gap-lg .col-sm-1, .row.gap-lg .col-sm-10, .row.gap-lg .col-sm-11, .row.gap-lg .col-sm-12, .row.gap-lg .col-sm-2, .row.gap-lg .col-sm-3, .row.gap-lg .col-sm-4, .row.gap-lg .col-sm-5, .row.gap-lg .col-sm-6, .row.gap-lg .col-sm-7, .row.gap-lg .col-sm-8, .row.gap-lg .col-sm-9, .row.gap-lg .col-sm-auto, .row.gap-lg .col-xl, .row.gap-lg .col-xl-1, .row.gap-lg .col-xl-10, .row.gap-lg .col-xl-11, .row.gap-lg .col-xl-12, .row.gap-lg .col-xl-2, .row.gap-lg .col-xl-3, .row.gap-lg .col-xl-4, .row.gap-lg .col-xl-5, .row.gap-lg .col-xl-6, .row.gap-lg .col-xl-7, .row.gap-lg .col-xl-8, .row.gap-lg .col-xl-9, .row.gap-lg .col-xl-auto {
    padding-left: 40px;
    padding-right: 40px
}

@media (max-width:991px) {
    .row.gap-lg .col, .row.gap-lg .col-1, .row.gap-lg .col-10, .row.gap-lg .col-11, .row.gap-lg .col-12, .row.gap-lg .col-2, .row.gap-lg .col-3, .row.gap-lg .col-4, .row.gap-lg .col-5, .row.gap-lg .col-6, .row.gap-lg .col-7, .row.gap-lg .col-8, .row.gap-lg .col-9, .row.gap-lg .col-auto, .row.gap-lg .col-lg, .row.gap-lg .col-lg-1, .row.gap-lg .col-lg-10, .row.gap-lg .col-lg-11, .row.gap-lg .col-lg-12, .row.gap-lg .col-lg-2, .row.gap-lg .col-lg-3, .row.gap-lg .col-lg-4, .row.gap-lg .col-lg-5, .row.gap-lg .col-lg-6, .row.gap-lg .col-lg-7, .row.gap-lg .col-lg-8, .row.gap-lg .col-lg-9, .row.gap-lg .col-lg-auto, .row.gap-lg .col-md, .row.gap-lg .col-md-1, .row.gap-lg .col-md-10, .row.gap-lg .col-md-11, .row.gap-lg .col-md-12, .row.gap-lg .col-md-2, .row.gap-lg .col-md-3, .row.gap-lg .col-md-4, .row.gap-lg .col-md-5, .row.gap-lg .col-md-6, .row.gap-lg .col-md-7, .row.gap-lg .col-md-8, .row.gap-lg .col-md-9, .row.gap-lg .col-md-auto, .row.gap-lg .col-sm, .row.gap-lg .col-sm-1, .row.gap-lg .col-sm-10, .row.gap-lg .col-sm-11, .row.gap-lg .col-sm-12, .row.gap-lg .col-sm-2, .row.gap-lg .col-sm-3, .row.gap-lg .col-sm-4, .row.gap-lg .col-sm-5, .row.gap-lg .col-sm-6, .row.gap-lg .col-sm-7, .row.gap-lg .col-sm-8, .row.gap-lg .col-sm-9, .row.gap-lg .col-sm-auto, .row.gap-lg .col-xl, .row.gap-lg .col-xl-1, .row.gap-lg .col-xl-10, .row.gap-lg .col-xl-11, .row.gap-lg .col-xl-12, .row.gap-lg .col-xl-2, .row.gap-lg .col-xl-3, .row.gap-lg .col-xl-4, .row.gap-lg .col-xl-5, .row.gap-lg .col-xl-6, .row.gap-lg .col-xl-7, .row.gap-lg .col-xl-8, .row.gap-lg .col-xl-9, .row.gap-lg .col-xl-auto {
        padding-left: 15px !important;
        padding-right: 15px !important
    }
}

.has-anim {
    opacity: 0;
    visibility: hidden;
    transition: .4s ease-out;
    transform: translateY(25px) scale(.99)
}

.has-anim.fade {
    transform: translateY(8px) scale(1)
}

.has-anim.visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(0) scale(1)
}

.has-anim.anim-delay-1 {
    transition-delay: .1s
}

.has-anim.anim-delay-2 {
    transition-delay: .2s
}

.has-anim.anim-delay-3 {
    transition-delay: .3s
}

.has-anim.anim-delay-4 {
    transition-delay: .4s
}

.has-anim.anim-delay-6 {
    transition-delay: .6s
}

.has-anim.anim-delay-7 {
    transition-delay: .7s
}

.has-anim.anim-delay-8 {
    transition-delay: .8s
}

.has-anim.anim-delay-9 {
    transition-delay: .9s
}

@media (max-width:767px) {
    .has-anim {
        transition-delay: 0s !important
    }
}

.section {
    padding: 100px 0
}

.section.pt-n30 {
    padding-top: 70px
}

@media (max-width:767px) {
    .section {
        padding: 80px 0
    }

    .section.pt-n30 {
        padding-top: 50px
    }
}

.section-sm {
    padding: 70px 0
}

.section-sm.pt-n30 {
    padding-top: 40px
}

.section-title {
    margin-bottom: 60px
}

.section-title p.mb-2 {
    font-weight: 500
}

.section-title .title {
    font-weight: 500;
    font-size: 34px
}

@media (max-width:767px) {
    .section-title .title {
        font-size: 28px
    }
}

.section-title .title::after {
    content: ".";
    color: #00b75f
}

.lead {
    font-size: 22px;
    font-weight: 400;
    color: #000;
}

@media (max-width:767px) {
    .lead {
        font-size: 17px
    }
}

.swiper-wrapper {
    transition-timing-function: cubic-bezier(.165, .84, .44, 1) !important
}

.filter-select {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-right: 1.5%;
}

.filter-box {
    min-width: 20%;
}

.spinner {
    display: flex;
    justify-content: center;
    width: 100%;
}

.form-control {
    height: 48px;
    padding: 0 15px;
    border-width: 1px;
    border-radius: 0 !important;
    margin-bottom: 30px
}

.form-control:focus {
    box-shadow: none;
    outline: 0;
    border-color: #00b75f
}

textarea.form-control {
    min-height: 150px;
    padding-top: 15px
}

.custom-select {
    background: #fff url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='14' height='14' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='currentColor'/></g></svg>") no-repeat right 15px center
}

.tooltip {
    font-family: "Space Grotesk", sans-serif;
    font-size: 13px;
    z-index: 9999
}

.tooltip.show {
    opacity: 1
}

.tooltip .tooltip-inner {
    background-color: #001213;
    border-radius: 3px;
    padding: 8px 14px
}

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
    border-top-color: #001213 !important
}

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
    border-right-color: #001213 !important
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
    border-bottom-color: #001213 !important
}

.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
    border-left-color: #001213 !important
}

.list-inline-item:not(:last-child) {
    margin-right: 1.4rem
}

.social-icons li a {
    font-size: 20px
}

.line-height-1 {
    line-height: 1
}

.font-weight-400 {
    font-weight: 400
}

.font-weight-500 {
    font-weight: 500
}

.content .h1, .content .h2, .content .h3, .content .h4, .content .h5, .content .h6, .content h1, .content h2, .content h3, .content h4, .content h5, .content h6, .font-weight-600 {
    font-weight: 600
}

.font-weight-700 {
    font-weight: 700
}

.text-primary {
    color: #00b75f !important
}

.bg-primary {
    background-color: #00b75f !important
}

a.text-primary:hover {
    color: #00ea79 !important
}

.content .h1:not(.text-white), .content .h2:not(.text-white), .content .h3:not(.text-white), .content .h4:not(.text-white), .content .h5:not(.text-white), .content .h6:not(.text-white), .content h1:not(.text-white), .content h2:not(.text-white), .content h3:not(.text-white), .content h4:not(.text-white), .content h5:not(.text-white), .content h6:not(.text-white), .text-dark {
    color: #001213
}

.content a.h1:hover:not(.text-white), .content a.h2:hover:not(.text-white), .content a.h3:hover:not(.text-white), .content a.h4:hover:not(.text-white), .content a.h5:hover:not(.text-white), .content a.h6:hover:not(.text-white), a.text-dark:hover {
    color: #00b75f
}

.bg-dark {
    background-color: #001213 !important
}

.text-light {
    color: #bbb !important
}

.bg-light {
    background-color: #bbb !important
}

.text-underline {
    text-decoration: underline
}

.overflow-initial {
    overflow: initial
}

.text-circleed {
    position: relative
}

.text-circleed svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 115%;
    stroke-dasharray: 520;
    stroke-dashoffset: 520
}

@-webkit-keyframes svg_line {
    to {
        stroke-dashoffset: 0
    }
}

@keyframes svg_line {
    to {
        stroke-dashoffset: 0
    }
}

.visible .text-circleed svg {
    -webkit-animation: svg_line 1.5s linear forwards;
    animation: svg_line 1.5s linear forwards
}

.scroll-progress {
    position: fixed;
    right: 50px;
    bottom: 50px;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    z-index: 14;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transform: translateY(15px);
    transition: .2s linear;
    mix-blend-mode: difference
}

@media (max-width:575px) {
    .scroll-progress {
        right: 15px;
        bottom: 15px;
        height: 42px;
        width: 42px
    }
}

.scroll-progress.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0)
}

.scroll-progress::after, .scroll-progress::before {
    position: absolute;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='451.8' height='451.8' fill='%23ffffff'%3E%3Cpath d='M248.3 106.4l194.3 194.3a31.6 31.6 0 11-44.8 44.7L226 173.5 54 345.5a31.6 31.6 0 01-44.7-44.8l194.3-194.3a31.5 31.5 0 0144.7 0z'/%3E%3C/svg%3E");
    left: 7%;
    top: 7%;
    height: 86%;
    width: 86%;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 13px;
    border-radius: 50%;
    transition: .2s;
    mix-blend-mode: difference
}

.scroll-progress::before {
    background-color: #fff;
    background-image: none;
    transform: scale(.5);
    opacity: 0
}

.scroll-progress svg path {
    fill: none
}

.scroll-progress svg.progress-circle path {
    stroke: #fff;
    mix-blend-mode: difference;
    stroke-width: 4;
    box-sizing: border-box;
    transition: all .2s linear
}

.scroll-progress:hover::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='451.8' height='451.8' fill='%23FFFFFF'%3E%3Cpath d='M248.3 106.4l194.3 194.3a31.6 31.6 0 11-44.8 44.7L226 173.5 54 345.5a31.6 31.6 0 01-44.7-44.8l194.3-194.3a31.5 31.5 0 0144.7 0z'/%3E%3C/svg%3E")
}

.scroll-progress:hover::before {
    transform: scale(1);
    opacity: 1
}

.accordion .content :not(:last-child) {
    margin-bottom: 20px !important
}

.accordion .content ol li:not(:last-child), .accordion .content ul li:not(:last-child) {
    margin-bottom: 7px !important
}

.accordion .card {
    border: 0 !important;
    border-radius: 0
}

.accordion .card:not(:last-child) {
    margin-bottom: 10px
}

.accordion .card .card-header {
    margin-bottom: 0
}

.accordion .card-body {
    font-size: 17px;
    padding: 25px;
    background-color: #fbfdff;
    border: 1px solid rgba(0, 0, 0, .125);
    border-top: 0
}

.accordion .card-header a {
    font-size: 18px;
    padding: 20px 30px;
    padding-right: 65px;
    background: #001213;
    color: #fff;
    position: relative
}

.accordion .card-header a::after {
    font-family: 'Line Awesome Free';
    font-weight: 900;
    content: "\f067";
    transition: .3s cubic-bezier(.7, 0, .3, 1);
    position: absolute;
    right: 30px;
    top: 50%;
    line-height: 1
}

.accordion .card-header a[aria-expanded=true]::after {
    transform: translateY(-50%) rotate(-136deg)
}

.accordion .card-header a[aria-expanded=false]::after {
    transform: translateY(-50%) rotate(0)
}

.content * {
    word-break: break-word;
    overflow-wrap: break-word
}

.content :not(:last-child) {
    margin-bottom: 15px !important
}

.content .h1, .content .h2, .content .h3, .content .h4, .content .h5, .content .h6, .content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
    margin-bottom: 20px;
    margin-top: 20px
}

.content.content-dark .h2:not(.text-white), .content.content-dark .h3:not(.text-white), .content.content-dark .h4:not(.text-white), .content.content-dark .h5:not(.text-white), .content.content-dark .h6:not(.text-white), .content.content-dark h2:not(.text-white), .content.content-dark h3:not(.text-white), .content.content-dark h4:not(.text-white), .content.content-dark h5:not(.text-white), .content.content-dark h6:not(.text-white) {
    color: #ddd !important
}

.content img {
    max-width: 100%
}

.content a:not(.btn) {
    color: #333;
    text-decoration: underline
}

.content a:not(.btn):hover {
    color: #000;
    text-decoration: none
}

.content.content-dark a:not(.btn) {
    color: #fff;
    text-decoration: underline
}

.content.content-dark a:not(.btn):hover {
    color: #fff;
    text-decoration: none
}

.content ol {
    margin-left: 0;
    padding-left: 0;
    list-style-position: inside
}

.content ol li:not(:last-child) {
    margin-bottom: 7px !important
}

.content ul {
    padding-left: 0
}

.content ul li {
    position: relative;
    padding-left: 18px;
    list-style-type: none
}

.content ul li:not(:last-child) {
    margin-bottom: 7px !important
}

.content ul li::after {
    position: absolute;
    content: "";
    height: 7px;
    width: 7px;
    background-color: #000;
    opacity: .5;
    left: 0;
    top: 10px;
    transform: rotate(-45deg)
}

.content.content-dark ul li::after {
    background-color: #fff
}

.fade-slider {
    position: relative;
    overflow: hidden
}

.fade-slider img {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center
}

.fade-slider .slider-item:not(:first-child) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1
}

.navbar-brand {
    color: #fff !important;
    font-size: 30px
}

.navbar-brand img {
    width: 170px
}

.navbar-brand p {
    font-size: 32px;
    padding-top: 5px
}

.navbar-toggler {
    border: 0;
    outline: 0 !important;
    padding: 5px;
    padding-right: 0
}

.navbar-toggler svg {
    height: 22px;
    width: 22px;
    fill: #fff;
    transition: opacity .3s
}

.navbar-toggler:hover svg {
    opacity: .7
}

[data-toggle=navbar-menu-close] {
    display: none;
    text-align: right;
    margin-bottom: 15px;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 10px 0;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer
}

[data-toggle=navbar-menu-close] i {
    font-size: 15px;
    transform: translateY(1px)
}

.navbar-expanded [data-toggle=navbar-menu-close] {
    margin-right: 30px;
    transition: .3s
}

.navbar-expanded [data-toggle=navbar-menu-close] i {
    font-size: 25px
}

.navbar-expanded [data-toggle=navbar-menu-close]:hover {
    opacity: .5
}

.navbar-light [data-toggle=navbar-menu-close] {
    color: #001213
}

.sidenav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 150vh;
    width: 100vw;
    background-color: rgba(0, 18, 19, .7);
    z-index: 3333;
    transition: opacity .3s, visibility .3s;
    opacity: 0;
    visibility: hidden
}

.sidenav-overlay.show {
    opacity: 1;
    visibility: visible
}

.navbar-dark .navbar-toggler svg {
    color: #eee
}

.navbar-dark .navbar-nav .nav-link {
    color: #eee
}

.navbar-expanded.navbar-light .navbar-nav .nav-link {
    color: #000
}

.adjust-navigation-height {
    height: 106px
}

@media (max-width:991px) {
    .adjust-navigation-height {
        height: 90px
    }
}

.navigation {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 9992;
    transition: transform .5s cubic-bezier(.2, 1, .2, 1);
    will-change: transform;
    background-color: #001213
}

.navigation.visible {
    transition: 0s
}

.navigation.header-unpinned {
    transform: translateY(-100%)
}

.navigation.header-unpinned .navbar-collapse {
    top: 106px !important
}

@media (max-width:991px) {
    .navigation.header-unpinned .navbar-collapse {
        top: 90px !important
    }
}

.navigation .nav-item .nav-link {
    padding: 20px 23px;
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    position: relative
}

.navigation .navbar-dark .navbar-nav:hover .nav-item:not(:hover) .nav-link {
    color: rgba(255, 255, 255, .5)
}

.navigation .navbar-dark .navbar-nav .nav-link:focus, .navigation .navbar-dark .navbar-nav .nav-link:hover {
    color: #fff
}

.navigation .navbar-light .navbar-nav .nav-link:focus, .navigation .navbar-light .navbar-nav .nav-link:hover {
    color: #000
}

.navigation .navbar-light .navbar-nav:hover .nav-item:not(:hover) .nav-link {
    color: rgba(0, 0, 0, .5)
}

.navigation .dropdown-toggle {
    padding-right: 19px
}

.navigation .dropdown-toggle::after {
    border: 2px solid transparent;
    border-radius: 2px;
    border-right: 0;
    border-top: 0;
    content: "";
    height: .5em;
    width: .5em;
    position: relative;
    top: 1px;
    margin-left: 7px;
    pointer-events: none;
    transform: rotate(-45deg);
    transform-origin: center;
    border-color: inherit
}

.navigation .dropdown-menu {
    border: 0;
    z-index: 1;
    background-color: #011c1e;
    margin-top: 0;
    padding-bottom: 12px;
    padding: 0
}

.navigation .dropdown-menu .dropdown-submenu .dropdown-toggle {
    font-size: 15px;
    color: rgba(0, 18, 19, .9);
    padding: 14px 1rem 10px 1rem;
    border-radius: 4px
}

@media (max-width:1200px) {
    .navigation .dropdown-menu .dropdown-submenu .dropdown-toggle {
        padding: 12px 1rem 8px 1rem;
        font-weight: 600
    }
}

.navigation .dropdown-menu .dropdown-submenu .dropdown-toggle:active, .navigation .dropdown-menu .dropdown-submenu .dropdown-toggle:focus, .navigation .dropdown-menu .dropdown-submenu .dropdown-toggle:hover {
    color: #00b75f;
    background-color: rgba(0, 183, 95, .15)
}

.navigation .dropdown-menu li:first-child a {
    padding-top: 18px
}

.navigation .dropdown-menu li:last-child a {
    padding-bottom: 18px
}

.navigation .dropdown-menu .dropdown-item {
    padding: 11px 20px 7px 20px;
    font-weight: 500;
    font-size: 14px;
    color: #fff
}

.navigation .dropdown-menu .dropdown-item:active, .navigation .dropdown-menu .dropdown-item:focus, .navigation .dropdown-menu .dropdown-item:hover {
    color: #fff;
    background-color: transparent
}

@media (max-width:1200px) {
    .navigation .dropdown-menu .dropdown-item {
        padding: 8px 1rem 4px 1rem
    }
}

.navigation .dropdown-menu:hover li:not(:hover) .dropdown-item {
    color: rgba(255, 255, 255, .4)
}

@media only screen and (min-width:991px) {
    .navigation nav.navbar:not(.navbar-expanded) .dropdown .dropdown-menu {
        position: absolute;
        left: 0;
        display: block;
        opacity: 0;
        visibility: hidden;
        min-width: 200px;
        border-radius: 0;
        background-color: #00181a
    }

    .navigation nav.navbar:not(.navbar-expanded) .dropdown:hover .dropdown-menu {
        opacity: 1;
        visibility: visible
    }
}

@media (max-width:991px) {
    .navigation .navbar-collapse {
        position: fixed;
        top: 0;
        right: 0;
        width: 280px;
        max-width: 100%;
        height: 100vh;
        background-color: #000d0e;
        padding: 30px;
        padding-right: 0;
        transform: translateX(100%);
        z-index: 9999
    }
}

@media (max-width:991px) and (min-width:768px) {
    .navigation .navbar-collapse {
        width: 300px
    }
}

@media (max-width:991px) {
    .navigation .navbar-collapse.show {
        transform: translateX(0);
        transition: transform .5s cubic-bezier(.2, 1, .2, 1)
    }

    .navigation .navbar-actions {
        padding-right: 30px
    }

    .navigation .navbar-nav {
        margin-bottom: 15px;
        padding-right: 30px;
        max-height: calc(100vh - 120px);
        overflow-y: auto
    }

    .navigation .navbar-nav::-webkit-scrollbar {
        width: 5px;
        background-color: #0f111b
    }

    .navigation .nav-item .nav-link {
        padding: 15px 0
    }

    .navigation .dropdown-menu {
        background-color: #001213;
        border-radius: 0
    }

    .navigation .dropdown-menu .dropdown-item {
        padding: 8px 1rem 6px 1rem
    }

    .navigation .navbar-light .dropdown-menu {
        background-color: #fff
    }

    .navigation .dropdown-toggle::after {
        float: right;
        margin-right: 5px
    }

    .navigation .btn {
        display: block
    }

    .navigation [data-toggle=navbar-menu-close] {
        display: block
    }
}

.navigation .navbar-expanded .navbar-collapse {
    position: fixed;
    top: 0;
    right: auto;
    left: 0;
    width: 320px;
    max-width: 100%;
    height: 100vh;
    padding: 30px;
    padding-right: 0;
    transform: translateX(-100%);
    z-index: 9999;
    transition: transform .5s cubic-bezier(.2, 1, .2, 1)
}

.navigation .navbar-expanded .navbar-collapse.show {
    transform: translateX(0)
}

.navigation .navbar-expanded.navbar-light .navbar-collapse {
    background-color: #fff
}

.navigation .navbar-expanded .navbar-actions {
    position: absolute;
    bottom: 35px;
    padding-top: 3px;
    background-color: #fff
}

.navigation .navbar-expanded .navbar-actions .social-icons a:hover {
    color: #001213 !important;
    opacity: .7
}

.navigation .navbar-expanded .navbar-nav {
    margin-bottom: 15px;
    padding-right: 30px;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
    position: absolute;
    width: calc(100% - 30px);
    top: 50%;
    transform: translateY(-50%)
}

.navigation .navbar-expanded .navbar-nav::-webkit-scrollbar {
    width: 5px;
    background-color: #ddd
}

.navigation .navbar-expanded .nav-item .nav-link {
    padding: 13px 0;
    font-size: 22px
}

.navigation .navbar-expanded .dropdown-menu {
    background-color: #001213;
    border-radius: 0
}

.navigation .navbar-expanded .dropdown-menu .dropdown-item {
    padding: 8px 1rem 6px 1rem
}

.navigation .navbar-expanded .navbar-light .dropdown-menu {
    background-color: #fff
}

.navigation .navbar-expanded .dropdown-toggle::after {
    float: right;
    margin-right: 5px
}

.navigation .navbar-expanded [data-toggle=navbar-menu-close] {
    display: block
}

.banner {
    padding-top: 130px;
    padding-bottom: 150px;
    color: #aaa
}

@media (max-width:991px) {
    .banner {
        padding: 90px 0
    }
}

@media (max-width:767px) {
    .banner {
        padding-top: 60px
    }
}

.banner .banner-title {
    font-size: 56px;
    font-weight: 300;
    line-height: 1.2
}

@media (max-width:991px) {
    .banner .banner-title {
        font-size: 50px
    }
}

@media (max-width:767px) {
    .banner .banner-title {
        font-size: 44px
    }
}

@media (max-width:575px) {
    .banner .banner-title {
        font-size: 38px
    }
}

@media (max-width:480px) {
    .banner .banner-title {
        font-size: 36px
    }
}

.banner .banner-shape {
    height: 600px;
    width: 560px;
    max-width: 100%;
    position: absolute;
    top: -50%;
    left: -200px;
    z-index: -1;
    opacity: .2;
    color: #332d2b
}

@media (max-width:767px) {
    .banner .banner-shape {
        left: -100px;
        opacity: .3
    }
}

.banner.personal-portfolio {
    padding: 100px 0 40px 0
}

@media (max-width:767px) {
    .banner.personal-portfolio {
        padding: 60px 0 0 0
    }
}

.banner.personal-portfolio .user-image {
    position: relative;
    padding: 20px;
    max-width: 100%
}

@media (max-width:991px) {
    .banner.personal-portfolio .user-image {
        padding: 15px;
        margin-bottom: 45px;
        width: 360px
    }
}

@media (max-width:767px) {
    .banner.personal-portfolio .user-image {
        width: 320px;
        padding: 10px
    }
}

@media (max-width:575px) {
    .banner.personal-portfolio .user-image {
        width: 280px
    }
}

.banner.personal-portfolio .user-image::after, .banner.personal-portfolio .user-image::before {
    position: absolute;
    content: "";
    height: calc(100% - 20px);
    width: 50%;
    background-color: #00b75f;
    right: 0;
    top: -20px;
    z-index: -2
}

@media (max-width:991px) {
    .banner.personal-portfolio .user-image::after, .banner.personal-portfolio .user-image::before {
        top: -15px
    }
}

@media (max-width:991px) {
    .banner.personal-portfolio .user-image::after, .banner.personal-portfolio .user-image::before {
        top: -10px
    }
}

.banner.personal-portfolio .user-image::before {
    top: auto;
    bottom: -20px;
    right: auto;
    left: 0
}

@media (max-width:991px) {
    .banner.personal-portfolio .user-image::before {
        bottom: -15px
    }
}

@media (max-width:767px) {
    .banner.personal-portfolio .user-image::before {
        bottom: -10px
    }
}

.banner.personal-portfolio .user-image .shape {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%
}

.banner.personal-portfolio .user-image .shape::before {
    content: "\f6bb";
    font-size: 48vw;
    color: #001213;
    line-height: 1;
    font-weight: 400;
    font-family: 'Line Awesome Free';
    font-weight: 900;
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

@media (max-width:991px) {
    .banner.personal-portfolio .user-image .shape::before {
        font-size: 62vw
    }
}

@media (max-width:767px) {
    .banner.personal-portfolio .user-image .shape::before {
        font-size: 85vh
    }
}

@media (max-width:575px) {
    .banner.personal-portfolio .user-image .shape::before {
        font-size: 110vh
    }
}

.page-header {
    padding: 50px 0 60px 0;
    color: #bbb
}

.portfolio-card {
    border: 0;
    border-radius: 0;
    overflow: hidden;
    background-color: transparent
}

.portfolio-card .card-img {
    border-radius: 0 !important
}

.card-img img, .card-img-overlay, .card-subtitle, .card-title {
    display: block;
    will-change: transform, opacity;
    transition: transform .7s cubic-bezier(.2, 1, .2, 1), opacity .7s cubic-bezier(.2, 1, .2, 1)
}

.card-subtitle a, .card-title a {
    color: #fff;
    text-decoration: underline;
    text-decoration-color: transparent;
    -webkit-text-decoration-color: transparent;
    -moz-text-decoration-color: transparent
}

.card-subtitle a:hover, .card-title a:hover {
    text-decoration: underline !important;
    text-decoration-color: #fff;
    -webkit-text-decoration-color: #fff;
    -moz-text-decoration-color: #fff
}

.card-overlay .card-img-overlay {
    top: auto;
    border-radius: 0;
    z-index: 3;
    padding: 3rem
}

@media (max-width:575px) {
    .card-overlay .card-img-overlay {
        padding: 2rem
    }
}

.card-overlay .card-title {
    transform: translateY(100%)
}

@media (max-width:991px) {
    .card-overlay .card-title {
        font-size: 30px
    }
}

.card-overlay .card-subtitle {
    font-size: 15px;
    opacity: .8;
    transform: translateY(calc(-100% - 15px))
}

.card-overlay:hover .card-img-overlay, .card-overlay:hover .card-subtitle, .card-overlay:hover .card-title {
    transform: translateY(0)
}

.card-img .img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    opacity: 0;
    background-color: #000;
    transition: .3s ease
}

.card-hover-zoom:hover .card-img img {
    transform: scale(1.2)
}

.card-hover-zoom:hover .card-img .img-overlay {
    opacity: .95
}

.portfolio-carousel-fluid .swiper-container {
    cursor: -webkit-grab;
    cursor: grab
}

.swiper-container {
    position: relative;
}

.portfolio-carousel-fluid .swiper-slide .portfolio-card {
    margin: 0 40px
}

@media (max-width:1200px) {
    .portfolio-carousel-fluid .swiper-slide .portfolio-card {
        margin: 0 30px
    }
}

@media (max-width:991px) {
    .portfolio-carousel-fluid .swiper-slide .portfolio-card {
        margin: 0 15px
    }
}

.portfolio-carousel-fluid .card-img-overlay {
    height: 100%;
    top: 0;
    padding: 1.25rem;
    transform: translateY(0);
    display: flex;
    align-items: center;
    justify-content: center
}

.portfolio-carousel-fluid .card-img-overlay .block .card-subtitle, .portfolio-carousel-fluid .card-img-overlay .block .card-title {
    opacity: 0;
    transition: transform .3s cubic-bezier(.2, 1, .2, 1), opacity .3s cubic-bezier(.2, 1, .2, 1) !important;
    transform: translateY(60px)
}

.portfolio-carousel-fluid .card-img-overlay .block .card-subtitle {
    transition-delay: 0s !important
}

.portfolio-carousel-fluid .card-hover-zoom:hover .card-img-overlay .block .card-subtitle, .portfolio-carousel-fluid .card-hover-zoom:hover .card-img-overlay .block .card-title {
    opacity: 1;
    transform: translateY(0)
}

.portfolio-carousel-fluid .card-hover-zoom:hover .card-img-overlay .block .card-subtitle {
    transition-delay: .2s !important
}

.portfolio-carousel-fluid .swiper-pagination-progressbar {
    position: absolute;
    top: auto;
    bottom: 10px;
    left: calc(50% - 165px);
    width: 330px;
    height: 3px;
    background: rgba(200, 200, 200, .4);
    transition-timing-function: cubic-bezier(.165, .84, .44, 1)
}

@media (max-width:575px) {
    .portfolio-carousel-fluid .swiper-pagination-progressbar {
        left: calc(50% - 100px);
        width: 200px
    }
}

.portfolio-carousel-fluid .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #001213;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: left top;
}

.portfolio-carousel-fluid .swipeCarousel-control {
    position: relative;
    height: 50px;
    top: -10px;
    pointer-events: none
}

.portfolio-carousel-fluid .swipeCarousel-control .activeslide, .portfolio-carousel-fluid .swipeCarousel-control .totalslide {
    color: #000;
    position: absolute;
    top: calc(50% + 5px);
    left: calc(50% - 200px);
    line-height: 1;
    font-weight: 600
}

@media (max-width:575px) {
    .portfolio-carousel-fluid .swipeCarousel-control .activeslide, .portfolio-carousel-fluid .swipeCarousel-control .totalslide {
        left: calc(50% - 140px)
    }
}

.portfolio-carousel-fluid .swipeCarousel-control .totalslide {
    left: auto;
    right: calc(50% - 200px)
}

@media (max-width:575px) {
    .portfolio-carousel-fluid .swipeCarousel-control .totalslide {
        right: calc(50% - 140px)
    }
}

.portfolio-carousel-fluid .swiper-button {
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 700;
    color: #000;
    padding: 10px 12px
}

.prevent-select {
    user-select: none;
}

@media (max-width:991px) {
    .portfolio-masonry .row {
        margin-bottom: 30px
    }
}

.portfolio-masonry .portfolio-item {
    margin-bottom: 80px
}

@media (max-width:991px) {
    .portfolio-masonry .portfolio-item {
        margin-bottom: 30px
    }
}

.portfolio-grid-creative .portfolio-card .block {
    background-color: #fff;
    position: relative;
    z-index: 2;
    margin: 0 30px;
    margin-top: -40px;
    padding: 15px 25px;
    transition: margin .3s;
    text-align: center
}

.portfolio-grid-creative .portfolio-card:hover .block {
    margin-top: -50px;
    margin-left: 15px;
    margin-right: 15px
}

.portfolio-floating .portfolio-card {
    overflow: initial;
    position: initial
}

.floating-info-block-tile {
    position: fixed;
    z-index: 6;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    padding: 18px 20px 10px 20px;
    color: #000;
    pointer-events: none
}

.floating-info-block-tile>* {
    color: #000 !important
}

.floating-info-block-tile::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    transform: scale(.4);
    background-color: #fff;
    bottom: 0;
    left: 0;
    z-index: -1
}

.floating-info-block-tile.visible {
    opacity: 1;
    visibility: visible
}

.floating-info-block-tile.visible::after {
    transform: scale(1);
    transition: .5s cubic-bezier(.2, 1, .2, 1)
}

.floating-image-block {
    position: relative
}

.floating-image-block::before {
    content: attr(data-text);
    font-weight: 700;
    margin-right: 15px;
    position: absolute;
    bottom: 27px;
    left: 30px;
    transition: .3s cubic-bezier(.2, 1, .2, 1);
    transition-delay: .2s;
    opacity: 0
}

.floating-image-block:hover::before {
    left: 0;
    opacity: 1
}

.floating-image-block .block {
    position: relative;
    z-index: 1;
    display: inline-block
}

.floating-image-block .block .floating-image-link {
    display: inline-block;
    z-index: 1;
    line-height: 1
}

.floating-image-block .block .floating-image-link a {
    padding: 20px 0;
    display: block;
    font-size: 62px
}

@media (max-width:767px) {
    .floating-image-block .block .floating-image-link a {
        font-size: 52px
    }
}

@media (max-width:575px) {
    .floating-image-block .block .floating-image-link a {
        font-size: 42px
    }
}

.floating-image-block .block .floating-image-link:hover a {
    padding-left: 30px;
    color: #fff !important
}

.floating-image-block .block .floating-image {
    width: 380px;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    pointer-events: none
}

@media (max-width:991px) {
    .floating-image-block .block .floating-image {
        width: 280px
    }
}

.floating-image-block .block .floating-image .image {
    overflow: hidden
}

.floating-image-block .block .floating-image .image img {
    max-width: 100%;
    transform: scale(1.2);
    opacity: 0;
    transition: transform 1s cubic-bezier(.2, 1, .2, 1), opacity .1s ease-out
}

.floating-image-block .block.enter {
    z-index: 5
}

.floating-image-block .block.enter .image img {
    transform: scale(1);
    opacity: 1
}

.broken-portfolio .card-img-overlay {
    height: 100%;
    top: 0;
    padding: 1.25rem;
    transform: translateY(0);
    display: flex;
    align-items: center;
    justify-content: center
}

.broken-portfolio .card-img-overlay .block .card-subtitle, .broken-portfolio .card-img-overlay .block .card-title {
    opacity: 0;
    transition: transform .3s cubic-bezier(.2, 1, .2, 1), opacity .3s cubic-bezier(.2, 1, .2, 1) !important;
    transform: translateY(60px)
}

.broken-portfolio .card-img-overlay .block .card-subtitle {
    transition-delay: 0s !important
}

.broken-portfolio .card-hover-zoom:hover .card-img-overlay .block .card-subtitle, .broken-portfolio .card-hover-zoom:hover .card-img-overlay .block .card-title {
    opacity: 1;
    transform: translateY(0)
}

.broken-portfolio .card-hover-zoom:hover .card-img-overlay .block .card-subtitle {
    transition-delay: .2s !important
}

.service-item {
    padding: 0 10px;
    margin-bottom: 45px
}

.service-icon-lg {
    position: absolute;
    width: calc(70% - 30px);
    opacity: .05;
    pointer-events: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.service-info-list {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0
}

.service-info-list li {
    list-style-type: none;
    margin-bottom: 10px;
    font-weight: 500;
    flex: 0 0 calc(47% - 5px);
    width: 100%;
    margin-right: 5px
}

.clients-logo-grid {
    display: grid;
    row-gap: 60px;
    -moz-column-gap: 40px;
    column-gap: 40px;
    grid-template-columns: repeat(5, minmax(0, 1fr))
}

@media (max-width:991px) {
    .clients-logo-grid {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        -moz-column-gap: 20px;
        column-gap: 20px
    }
}

@media (max-width:767px) {
    .clients-logo-grid {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }
}

@media (max-width:420px) {
    .clients-logo-grid {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
}

.clients-logo-grid .logo-item {
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-self: center;
    padding: 0 30px;
    opacity: .6;
    transition: .3s
}

.clients-logo-grid .logo-item:hover {
    opacity: 1
}

@media (max-width:991px) {
    .clients-logo-grid .logo-item {
        padding: 0 20px
    }
}

.clients-logo-grid .logo-item img {
    width: 100%;
    max-width: 100%
}

.review-carousel .swiper-wrapper {
    cursor: -webkit-grab;
    cursor: grab;
    padding-bottom: 80px
}

.review-carousel .review-icon {
    color: #555;
    margin-bottom: 25px
}

.review-carousel.review-carousel-light .review-icon {
    color: #eee
}

.review-carousel .review-text {
    color: #001213
}

.review-carousel.review-carousel-light .review-text {
    color: #fff
}

.review-carousel .review-text {
    line-height: 1.5;
    font-weight: 400
}

.review-carousel .review-item {
    text-align: center;
    transition: .3s;
    opacity: 0
}

.review-carousel .swiper-slide-active .review-item {
    opacity: 1
}

.review-carousel .review-author {
    margin-top: 20px;
    display: inline-block;
    font-weight: 600
}

.review-carousel .swipeCarousel-control {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%
}

.review-carousel .swipeCarousel-control div {
    display: inline-block;
    position: relative;
    width: 75px;
    font-weight: 500;
    text-align: center
}

.review-carousel .swipeCarousel-control div:first-child::after {
    content: "";
    height: 1px;
    width: 20px;
    background-color: #001213;
    top: 45%;
    right: -13px;
    position: absolute
}

.review-carousel.review-carousel-light .swipeCarousel-control div {
    color: #aaa
}

.review-carousel.review-carousel-light .swipeCarousel-control div:first-child::after {
    background-color: #fff
}

.work-process-item {
    margin: auto;
    text-align: center;
    border-radius: 4px;
    color: #fff;
    overflow: hidden;
    margin-top: 38px
}

@media (max-width:991px) {
    .work-process-item {
        padding: 0 50px
    }
}

@media (max-width:767px) {
    .work-process-item {
        padding: 0
    }
}

@media (max-width:575px) {
    .work-process-item {
        width: 60%
    }
}

@media (max-width:420px) {
    .work-process-item {
        width: 100%;
        padding: 0 20px
    }
}

.work-process-item .process-title {
    position: relative
}

.work-process-item .process-title::after {
    position: absolute;
    content: "0";
    top: 0;
    left: 50%;
    color: #fff;
    opacity: .015;
    font-size: 300px;
    font-weight: 900;
    line-height: 0;
    transform: translateX(-50%);
    pointer-events: none
}

@media (max-width:767px) {
    .work-process-item .process-title::after {
        font-size: 210px
    }
}

.work-process-item .process-text {
    -webkit-text-stroke: 1px #fff;
    -webkit-text-fill-color: transparent;
    font-size: 90px;
    font-weight: 700;
    line-height: 1.3
}

@media (max-width:767px) {
    .work-process-item .process-text {
        font-size: 60px
    }
}

.work-process-item.focus .process-text, .work-process-item:hover .process-text {
    -webkit-animation: shadow-anim .5s cubic-bezier(.165, .84, .44, 1) infinite;
    animation: shadow-anim .5s cubic-bezier(.165, .84, .44, 1) infinite
}

@-webkit-keyframes shadow-anim {
    0% {
        text-shadow: 4px 4px rgba(0, 183, 95, .6), -5px 4px rgba(240, 255, 24, .8)
    }

    25% {
        text-shadow: -5px 4px rgba(33, 150, 243, .7), 4px 4px rgba(240, 255, 24, .6)
    }

    50% {
        text-shadow: 4px -4px rgba(3, 236, 139, .8), -5px 4px rgba(33, 150, 243, .2)
    }

    75% {
        text-shadow: -4px -4px rgba(240, 255, 24, .7), 4px 4px rgba(3, 236, 139, .6)
    }

    100% {
        text-shadow: 4px 4px rgba(0, 183, 95, .7), -5px 4px rgba(255, 87, 219, .4)
    }
}

@keyframes shadow-anim {
    0% {
        text-shadow: 4px 4px rgba(0, 183, 95, .6), -5px 4px rgba(240, 255, 24, .8)
    }

    25% {
        text-shadow: -5px 4px rgba(33, 150, 243, .7), 4px 4px rgba(240, 255, 24, .6)
    }

    50% {
        text-shadow: 4px -4px rgba(3, 236, 139, .8), -5px 4px rgba(33, 150, 243, .2)
    }

    75% {
        text-shadow: -4px -4px rgba(240, 255, 24, .7), 4px 4px rgba(3, 236, 139, .6)
    }

    100% {
        text-shadow: 4px 4px rgba(0, 183, 95, .7), -5px 4px rgba(255, 87, 219, .4)
    }
}

.blog-card {
    position: relative;
    margin-top: 30px;
    height: calc(100% - 30px);
    padding: 30px
}

@media (max-width:420px) {
    .blog-card {
        padding: 20px
    }
}

.center-image-flex {
    display: flex;
    justify-content: center;
}

.blog-card .card-background {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: .05
}

.blog-card .card-image {
    overflow: hidden;
    display: block
}

.blog-card .card-image img {
    transform: scale(1.02);
    transition: .3s;
    transform-origin: right
}

.blog-card .card-image:hover img {
    transform-origin: left
}

.blog-card .card-text {
    font-size: 15px
}

.blog-card .card-tag {
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: block
}

.blog-card .card-tag a {
    color: #909090
}

.blog-card .card-tag a:hover {
    color: #444;
    text-decoration: underline
}

.blog-card .card-title a {
    display: inline-block;
    color: #001213;
    transition: .2s;
    text-decoration: underline;
    text-decoration-color: transparent;
    -webkit-text-decoration-color: transparent;
    -moz-text-decoration-color: transparent
}

.blog-card .card-title a:hover {
    color: #222;
    text-decoration-color: #444;
    -webkit-text-decoration-color: #444;
    -moz-text-decoration-color: #444
}

.blog-card .card-meta {
    font-size: 14px
}

.team-group-carousel .swiper-container {
    cursor: -webkit-grab;
    cursor: grab
}

@media (max-width:767px) {
    .team-group-carousel .swiper-container {
        padding-right: 100px
    }
}

@media (max-width:575px) {
    .team-group-carousel .swiper-container {
        padding-right: 75px
    }
}

@media (max-width:480px) {
    .team-group-carousel .swiper-container {
        padding-right: 50px
    }
}

.team-group-carousel .swiper-slide {
    display: flex;
    justify-content: center
}

.team-group-carousel .team-group-image {
    text-align: center;
    overflow: hidden;
    display: inline-block
}

.team-group-carousel .team-group-image img {
    max-width: 100%;
    transition: 2s cubic-bezier(.165, .84, .44, 1)
}

.team-group-carousel .team-group-image img.hold {
    transform: scale(1.2);
    transition: .3s cubic-bezier(.165, .84, .44, 1)
}

@media (max-width:420px) {
    .row-team .col-6 {
        flex: 0 0 100%;
        max-width: 100%
    }
}

.team-item {
    margin-top: 35px
}

.team-item .block img {
    transition: .3s
}

.team-item .block .social-profile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(rgba(0, 0, 0, 0) 0, #000 100%);
    z-index: 2;
    opacity: 0;
    transition: .3s
}

@media (max-width:480px) {
    .team-item .block .social-profile ul {
        text-align: center
    }
}

.team-item .block .social-profile ul li {
    transform: translateY(-30px);
    opacity: 0;
    transition: transform .3s, opacity .2s
}

.team-item .block .social-profile ul li:nth-child(2) {
    transition-delay: .08s
}

.team-item .block .social-profile ul li:nth-child(3) {
    transition-delay: .16s
}

.team-item .block .social-profile ul li:nth-child(4) {
    transition-delay: .24s
}

.team-item .block .social-profile ul li:nth-child(5) {
    transition-delay: .32s
}

.team-item .block .social-profile ul li:nth-child(6) {
    transition-delay: .4s
}

@media (max-width:480px) {
    .team-item .block .social-profile ul li {
        margin: 0 .5rem
    }
}

.team-item .block .social-profile ul li a {
    font-size: 22px;
    color: #fff
}

.team-item .block .social-profile ul li a:hover {
    opacity: .7
}

.team-item:hover .block img {
    transform: scale(1.1)
}

.team-item:hover .block .social-profile {
    opacity: 1
}

.team-item:hover .block .social-profile ul li {
    opacity: 1;
    transform: translateY(0)
}

@media (max-width:575px) {
    .team-item {
        width: 100%;
        max-width: 350px;
        margin-left: auto;
        margin-right: auto
    }
}

.open-positions-list li:not(:last-child) {
    padding-bottom: 25px;
    margin-bottom: 19px;
    border-bottom: 1px solid #20222d
}

@media (max-width:767px) {
    .open-positions-list li:not(:last-child) {
        padding-bottom: 20px;
        margin-bottom: 14px
    }
}

.about-me-image {
    border-top-left-radius: 300px;
    border-top-right-radius: 300px;
    box-shadow: 0 -5px 0 #00b75f, 0 -10px 0 rgba(0, 183, 95, .5), 0 -15px 0 rgba(0, 183, 95, .25)
}

.about-me-slider {
    position: sticky;
    top: 50px
}

.about-me-block:not(:last-child) {
    margin-bottom: 80px
}

.about-me-block .about-me-title {
    position: relative;
    padding-left: 30px;
    margin-bottom: 40px
}

.about-me-block .about-me-title::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 12px;
    background-color: #00b75f;
    top: 0;
    left: 0;
    border-radius: 15px
}

.pricing-plans .block {
    padding: 40px
}

@media (max-width:767px) {
    .pricing-plans .block {
        padding: 40px 30px
    }
}

@media (max-width:575px) {
    .pricing-plans .block {
        padding: 40px 20px
    }
}

.pricing-plans .price-wrapper {
    margin: 25px 0
}

.pricing-plans .price-wrapper .price {
    font-size: 48px;
    line-height: 56px;
    font-weight: 700
}

.footer-alt {
    padding: 130px 0
}

@media (max-width:991px) {
    .footer-alt {
        padding: 100px 0
    }

    .footer-alt .h1 {
        font-size: 36px
    }
}

.footer-link {
    margin-bottom: 10px
}

.footer-link li:not(:last-child) {
    margin-bottom: 10px
}

.footer-link.text-center li {
    margin-left: 13px !important;
    margin-right: 13px !important
}

.footer-link a {
    font-size: 15px;
    color: rgba(255, 255, 255, .8);
    text-decoration: underline;
    text-decoration-color: transparent;
    -webkit-text-decoration-color: transparent;
    -moz-text-decoration-color: transparent
}

.footer-link a:hover {
    color: rgba(255, 255, 255, .8);
    text-decoration-color: rgba(255, 255, 255, .8);
    -webkit-text-decoration-color: rgba(255, 255, 255, .8);
    -moz-text-decoration-color: rgba(255, 255, 255, .8)
}